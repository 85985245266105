'use strict';

module.exports = function () {
    var $pdComponent =  $('.js-hcp-pd-component');
    $pdComponent.find('.region').each(function () {
        if ($(this).html().length === 0) {
            $(this).addClass('d-none');
        }
    });
};
