'use strict';

window.$ = require('jquery');
window.jQuery = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/productGroupType'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('core/components/header'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/pageDesigner'));
    processInclude(require('store/components/floatLabels'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('core/components/toolTip'));
    processInclude(require('store/accelerator/slider'));
    processInclude(require('store/product/quickView'));
    processInclude(require('core/components/instagram'));
    processInclude(require('store/components/schema'));
    processInclude(require('./components/video'));
    processInclude(require('store/components/geoLocation'));
    processInclude(require('store/components/banner'));
    let features = JSON.parse(document.querySelector('body').dataset.features);
    if (features && features.enableChannelSite && features.csRefreshJsUrl) {
        processInclude(require('channelsight/csReInit'));
    }
});

/* Custom features */
processInclude(require('./components/phoneFormat'));

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
require('core/thirdParty/smartResize');
require('core/thirdParty/hoverIntent');
