'use strict';

var cart = require('store/cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.js-minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.js-minicart .minicart-quantity').text(count.quantityTotal);
            $('.js-minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.js-minicart').on('mouseenter focusin touchstart', function () {
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.js-minicart').data('action-url');
        var count = parseInt($('.js-minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.js-minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.js-minicart .popover').addClass('show');
                return;
            }

            $('.js-minicart .popover').addClass('show');
            $('.js-minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.js-minicart .popover').empty();
                $('.js-minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.js-minicart').has(e.target).length <= 0) {
            $('.js-minicart .popover').removeClass('show');
        }
    });
    $('.js-minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.js-minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.js-minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.js-minicart .popover').removeClass('show');
    });
    $('body').on('change', '.js-minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
