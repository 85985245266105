'use strict';

var timer;

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
  }

function getDataLayerYtObj(event) {
    var currentVidDuration = Math.ceil(event.target.getDuration());
    var percentage = (event.target.getCurrentTime() / currentVidDuration) * 100;
    var percentageCompleted = parseInt(percentage, 10);
    var groupName = getCookie('groupTypeInfo');
    var segment_type = groupName;
        var dataLayerYtObj = {
            'video_duration': currentVidDuration,
            'video_percent': percentageCompleted,
            'video_provider': 'YouTube',
            'video_title': event.target.videoTitle,
            'video_url': event.target.getVideoUrl(),
            'video_type': 'Promo',
            'segment_type': segment_type,
            'page_name': $('title').html(),
            'page_location': window.location.href,
            'hit_timestamp': new Date().toLocaleTimeString([], { hour12: false })
        };
    return dataLayerYtObj;
}

var passedPercentMilestone1 = false;
var passedPercentMilestone2 = false;
var passedPercentMilestone3 = false;
var passedPercentMilestone4 = false;

// checks the video progress and pushes the events
function checkProgress(event) {
    var datalayer = window.dataLayer || [];
    var videoDuration = event.target.getDuration();
    var currentTime = event.target.getCurrentTime();
    var videoPercentage = '';
    var percentageCompleted = '';
    videoPercentage = (currentTime / videoDuration) * 100;
    percentageCompleted = parseInt(videoPercentage, 10);
    var dataLayerYtObj = getDataLayerYtObj(event);

    if (percentageCompleted >= 25 && !passedPercentMilestone1) {
        // track video start
        dataLayerYtObj.event = 'video_progress';
        dataLayerYtObj.video_percent = 25;
        datalayer.push(dataLayerYtObj);
        passedPercentMilestone1 = true;
    } else if (percentageCompleted >= 50 && !passedPercentMilestone2) {
        dataLayerYtObj.event = 'video_progress';
        dataLayerYtObj.video_percent = 50;
        datalayer.push(dataLayerYtObj);
        passedPercentMilestone2 = true;
    } else if (percentageCompleted >= 75 && !passedPercentMilestone3) {
        dataLayerYtObj.event = 'video_progress';
        dataLayerYtObj.video_percent = 75;
        datalayer.push(dataLayerYtObj);
        passedPercentMilestone3 = true;
    } else if (percentageCompleted >= 99 && !passedPercentMilestone4) {
        dataLayerYtObj.event = 'video_progress';
        dataLayerYtObj.video_percent = 100;
        datalayer.push(dataLayerYtObj);
        passedPercentMilestone4 = true;
    }
}

function handleVideoProgressTracking(event, PLAYING) {
    if (event.data === PLAYING) {
        timer = setInterval(checkProgress, 1000, event);
    } else {
        clearInterval(timer);
    }
}

function onPlayerStateChange(event) {
    // tracking the video progress in below function checkProgress()
    var datalayer = window.dataLayer || [];
    const UNSTARTED = -1;
    const ENDED = 0;
    const PLAYING = 1;
    const PAUSED = 2;
    const BUFFERING = 3;
    // const CUED = 5;
    var dataLayerYtObj = getDataLayerYtObj(event);

    handleVideoProgressTracking(event, PLAYING);

    switch (event.data) {
        case UNSTARTED:
            // track video start
            dataLayerYtObj.event = 'video_start';
            datalayer.push(dataLayerYtObj)
            break;
        case ENDED:
            // track video end
            dataLayerYtObj.event = 'video_complete';
            dataLayerYtObj.video_percent = 100;
            datalayer.push(dataLayerYtObj)
            break;
        case PLAYING:
            // tracking the video progress in below function checkProgress()
            break;
        case PAUSED:
            dataLayerYtObj.event = 'video_paused';
            // track video pause
            datalayer.push(dataLayerYtObj)
            break;
        case BUFFERING:
            // track video buffering
            dataLayerYtObj.event = 'video_buffering';
            datalayer.push(dataLayerYtObj)
            break;
        // no default
    }
}


/**
 * Trigger Youtube Video
 *
 * @param {Node} div Container div element for Youtube video
 */
function initVideo(div) {
    var $videoContainer = $(div).closest('.video-panel-container');
    var playerVars = {
        'playsinline': 1,
    }
    if ($videoContainer.hasClass('autoplay')) {
        playerVars.autoplay = 1;
        playerVars.mute = 1;
    }
    if ($videoContainer.hasClass('preview-play')) {
        playerVars.autoplay = 1;
    }
    if (YT) {
        // eslint-disable-next-line no-undef
        new YT.Player(div.dataset.embed, {
            videoId: div.dataset.embed,
            playerVars: playerVars,
            events: {
                'onStateChange': onPlayerStateChange
            }
        });
    }
}

$('.experience-commerce_assets-videoPanel .video-preview-image').on('click', function () {
    $(this).addClass('hide');
    $(this).removeClass('show');
    $(this).closest('.experience-commerce_assets-videoPanel').find('.video-panel-container').removeClass('hide');
});
$('.primary-images').on('click', '.video-preview-image',  function () {
    $(this).addClass('hide');
    $(this).removeClass('show');
    $(this).closest('.glide-slide').find('.video-panel-container').removeClass('hide');
});
$('.howTo-video-panel-container').on('click', '.video-preview-image',  function () {
    $(this).addClass('hide');
    $(this).removeClass('show');
    $(this).closest('.howTo-video-panel-container').find('.video-panel-container').removeClass('hide');
});

/**
 * Initialize Intersection Observer for Youtube Lazyloading
 *
 * Preview image is not needed
 */
function initYoutubeIntersectionObserver() {
    const videos = document.querySelectorAll('.youtube');
    const config = { rootMargin: '100px' };
    let observer = new IntersectionObserver(function (entries, self) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Stop watching and load the video
                self.unobserve(entry.target);

                initVideo(entry.target);

            }
        });
    }, config);

    // Start Observer
    videos.forEach(video => {
        observer.observe(video);
    });
}

// Load as late as possible
window.addEventListener('load', initYoutubeIntersectionObserver);
