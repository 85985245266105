'use strict';

/**
 * Enforce XXX-XXX-XXXX phone formatting
 * @param {HTMLInputElement} telEl The phone number input element to enforce the change on.
 */
function phoneFormatting(telEl) {
    if (telEl != null) {
        telEl.addEventListener('input', (e) => {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        });
    }
}

module.exports = function () {
    phoneFormatting(document.querySelector('#phone'));
    phoneFormatting(document.querySelector('#shippingPhoneNumberdefault'));
    phoneFormatting(document.querySelector('#phoneNumber'));
};
